import React from "react";
import './index.scss'

import LogoSVG from '../../img/logo.svg'
import Whats from '../../img/whats.svg'
import LogoFooter from '../../img/logoFooterWhite.svg'

import LogoWeb from '../../img/logoWeb.svg'
const Logo = require('../../img/logoenviado.png')



//const Whats = require('../../img/iconwhats.png')
//const LogoFooter = require('../../img/logofooter.png')
const Enviado=() =>{
    return(
        <div className="containerEnviado">


            <div className="headerEnviado">
                <a href="http://landing1.resvera.com.mx/">
                <section className="logoEnviado" >
                    <img src={LogoSVG}></img>
                </section>
                </a>
                <section className="textHeader">
                    !Tu GIFT CARD ya está en tu correo!
                </section>
            </div>
            <div className="imagenEnviado"></div>
            <div className="textEnviado">
                <label>Nuestro concierge te contactará</label>
                <label>para agendar tu cita.</label>
            </div>

            <section className="hablarEspecialista">
                <a href="https://api.whatsapp.com/send?phone=525589469744&text=Me%20interesa%20conocer%20mas" target="_blank"
                  
                >
                <button >
                   <img src={Whats}></img> 
                   <label>Contactarlo ahora</label>
                </button>
                </a>
            </section>


            <div className="containerBotons"> 
              

                
                <a href="http://resvera.com.mx/" target="_blank" className="containerLogoWeb">
                    <img src={LogoWeb}></img>
                    <label>
                        www.resvera.com.mx
                    </label>
                </a>
            </div>



            <section className="footer">
                <div>
                    <div className="3"></div>
                    <label style={{fontStyle:"oblique"}} className="visita">Visita nuestra Wellness Beauty Clinic: </label>
                    <label  ><a href="https://g.page/Resvera?share" target="_blank" >Arquímedes 198, Polanco, Miguel Hidalgo, CDMX.</a></label>
                </div>
                <div>
                    <img src={LogoFooter}></img>
                </div>
            </section>
        </div>
    )
}

export default Enviado;