import react, {useState} from "react";
import './index.scss'
import emailjs from 'emailjs-com';
import LogoSVG from '../../img/nuevoLogo.svg'
import LogoFooterSVG from '../../img/logoFooterSVG.svg'

import {Link, Redirect} from 'react-router-dom'

import { History } from "history";
const axios = require('axios')

const LogoFooter = require('../../img/logofooter.png')
const Icono = require('../../img/icono.png')

const Home = () =>{


    const[text,setText] = useState('ELIJE LAS ZONAS DE TU INTERES ')

    const[zonatext, setZonatext] = useState('')

    const[seleccionUno,setSeleccionUno] = useState('pointOne')
    const[seleccionDos,setSeleccionDos] = useState('pointTwo')
    const[seleccionTres,setSeleccionTres] = useState('pointThree')
    const[seleccionCuatro,setSeleccionCuatro] = useState('pointFour')
    const[seleccionCinco,setSeleccionCinco] = useState('pointFive')
    const[seleccionSeis,setSeleccionSeis] = useState('pointSix')
    const[seleccionSiete,setSeleccionSiete] = useState('pointSeven')



    

    const frmContact ={
        reply_to:'vladi_ortega@hotmail.com', 
        nombre:'',
        telefono:'', 
        correo:'',     
        zona:text, 
    }
    const [contact,setContact] = useState(frmContact);
    const handleChange = e => { 
		const {name,value} = e.target;
		setContact({...contact,[name]:value}); 
   };

    const handleSubmit = e =>{
        alert("entro")
	    e.preventDefault();
     
		emailjs.send('default_service','template_ammjr2i', contact,'akEUA8UHaQ1B4AlAf')
		.then((response) => {
				   console.log('SUCCESS!', response.status, response.text);
				   setContact(frmContact);
                   window.location.reload()
                    setTimeout(function(){
                        window.location.reload()
                    }, 1000);
           
		}, (err) => {
				   console.log('FAILED...', err);
		});
   }



   function EnviarFormulario(){

 
    if(contact.nombre ===''){
        alert("Debe de ingresar un nombre !")
    }else if(contact.telefono ===''){
        alert("Debe de ingresar un Telefono !")
    }else if(contact.correo ===''){
        alert("Debe de ingresar un Correo !")
    }else if(contact.zona ===''){
        alert("Debe de ingresar una Zona !")
    }else{
    

       


            const options = {
                method: 'POST',
                url: 'http://18.191.31.74/enviarData',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                params: {
                    
                        email: contact.correo ,
                        nombre: contact.nombre, 
                        telefono: contact.telefono,
                        zona: contact.zona
                
                    
                }
              };


              axios.request(options)
                .then(function (response) {
                    console.log(response.data);
                    window.location.href = "/enviado"
                     
                })
                .catch(function (error) {
                    console.error(error);
                });


    }



    }



    function changeText(id){
        if(id===1){
            setText('1 - LINEAS DE EXPRESIÓN')
            setZonatext('LINEAS DE EXPRESIÓN')
            setSeleccionUno('pointOne selected')
            setSeleccionDos('pointTwo' )
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===2){
            setText('2 - MANCHAS')
            setZonatext('MANCHAS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo selected')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===3){
            setText('3 - OJERAS')
            setZonatext('OJERAS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree selected')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven ')
        }
        if(id===4){
            setText('4 - FOXY EYES')
            setZonatext('FOXY EYES')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour selected')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===5){
            setText('5 - PERFILAMIENTO MANDIBULAR')
            setZonatext('PERFILAMIENTO MANDIBULAR')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive selected')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven')
        }
        if(id===6){
            setText('6 - RINOMODELACIÓN')
            setZonatext('RINOMODELACIÓN')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix selected')
            setSeleccionSiete('pointSeven')
        }
        if(id===7){
            setText('7 - RELLENO DE LABIOS')
            setZonatext('RELLENO DE LABIOS')
            setSeleccionUno('pointOne')
            setSeleccionDos('pointTwo')
            setSeleccionTres('pointThree')
            setSeleccionCuatro('pointFour')
            setSeleccionCinco('pointFive')
            setSeleccionSeis('pointSix')
            setSeleccionSiete('pointSeven selected')
        }
    }


    return(
        <div className="generalContainerLanding">
            <section className="sectionOne">
                <div className="header">
                    <div className="footersectionOne">
                        <label className="texone">
                            Hoy date el lujo de
                            cuidarte con Resvera
                        </label>
                        
                        
                    </div>                                    
                    <div className="footersectionTwo">
                        <img src={LogoSVG}></img>
                    </div>
                </div>
              
                <div className="pointsSelection">
                    <div className={seleccionUno} onClick={()=> changeText(1)}>1</div>
                    <div className={seleccionDos} onClick={()=> changeText(2)}>2</div>
                    <div className={seleccionTres} onClick={()=> changeText(3)}>3</div>
                    <div className={seleccionCuatro} onClick={()=> changeText(4)}>4</div>
                    <div className={seleccionCinco} onClick={()=> changeText(5)}>5</div>
                    <div className={seleccionSeis} onClick={()=> changeText(6)}>6</div>
                    <div className={seleccionSiete} onClick={()=> changeText(7)}>7</div>
                </div>
                <div className="optionselected" >
                        {text}
                </div>
            </section>
            <div className="footersectionOne">
            </div>
            
            <section className="sectionCard">
               ¡Recibe $1,200 en tu primer tratamiento facial!
            </section>

            <section className="sectionForm">
                <form >
                    

                   


                    <div>
                        <label style={{fontWeight:600, fontStyle: "oblique",marginTop:"2vh", fontSize:"1.1rem",textAlign:"center",letterSpacing:"0.05rem" }}> Conócenos y haz de tu piel tu reflejo.</label>
                    </div>

                    <div className="agendacita">
                        
                        <label style={{fontWeight:600,textTransform:"uppercase"}}>Agenda tu cita aquí</label>
                        
                    </div>

                    <div>
                        <input type="text" placeholder="NOMBRE"  value={contact.nombre}  name="nombre" onChange={handleChange} >
                        </input>
                    </div>


                    <div>
                        <input type="text"  placeholder="TELÉFONO" value={contact.telefono}  name="telefono" onChange={handleChange}/>
                    </div>

                    <div>
                        <input type="text"  placeholder="CORREO ELECTRÓNICO" value={contact.correo}  name="correo" onChange={handleChange} />                        
                    </div>

                    <div>
                        <input type="text"  value={zonatext} name="zona" />
                        <label>
                           ZONA ELEGIDA
                        </label>
                    </div>




                </form>
            </section>
        
            <section className="giftcard">
                <button onClick={()=> EnviarFormulario()} >
                    Quiero mi Giftcard
                </button>
            </section>
            <section className="footer">
                <div>
                <label style={{fontStyle:"oblique"}}>Visita nuestro Wellness Beauty Clinic </label>
                    <label  ><a href="" style={{color:"#FFF"}}>Arquímedes 198, Polanco, Miguel Hidalgo, CDMX.</a></label>
                </div>
                <div>
                    <img src={LogoFooterSVG}></img>
                </div>
            </section>
        </div>
    )
}

export default Home;







